import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <footer>
    <nav className="navbar navbar-expand fixed-bottom justify-content-between">
      <ul className="navbar-nav">
        <li className="nav-item active">
          <a className="nav-link" href="https://github.com/misscoded" target="_blank" rel="noreferrer">GitHub</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="https://www.linkedin.com/in/alissarenz" target="_blank" rel="noreferrer">LinkedIn</a>
        </li>
      </ul>
      {/* <ul className="navbar-nav">
        <li className="nav-item active">
          <Link className="nav-link" to="/cv">
            View CV
            <span>➜</span>
          </Link>
        </li>
      </ul> */}
    </nav>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer;
